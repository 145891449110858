import './mock'
import Vue from 'vue'
import App from './App.vue'
import './styles/global.scss'
import './utils/globalComponents'
import router from "@/router";
import './eventBus'
import store from "./store";
store.dispatch('setting/fetchSetting')

import {showMessage} from "@/utils/showMessage";
import { serve_URL } from './api/serveUrl';


Vue.config.productionTip = false

Vue.prototype.$showMessage = showMessage;
Vue.prototype.$serve_URL = serve_URL;

import vLoading from './directives/loading'
import vLazy from './directives/lazy'
Vue.directive('loading', vLoading)
Vue.directive('lazy', vLazy)

Vue.config.devtools = true

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
