import Mock from "mockjs";

//后端开发好之后，注释掉对应的import
// import './banner'
// import "./blog";
// import "./setting"
// import "./about"
// import "./project"
// import "./message"
import "./fiction"

Mock.setup({
    timeout: '500-1000'
})
