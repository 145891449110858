export default [
    {
        name: 'home',
        path: '/',
        component: () => import('@/views/Home'),
        meta: {title: '首页'}
    },
    {
        name: 'about',
        path: '/about',
        component: () => import('@/views/About'),
        meta: {title: '关于'}
    },
    {
        name: 'blog',
        path: '/blog',
        component: () => import('@/views/Blog'),
        meta: {title: '博客'}
    },
    {
        name: 'categoryBlog',
        path: '/blog/cate/:categoryId',
        component: () => import('@/views/Blog'),
        meta: {title: '博客'}
    },
    {
        name: 'blogDetail',
        path: '/blog/:id',
        component: () => import('@/views/Blog/Detail.vue'),
        meta: {title: '博客详情'}
    },
    {
        name: 'message',
        path: '/message',
        component: () => import('@/views/Message'),
        meta: {title: '留言'}
    },
    {
        name: 'project',
        path: '/project',
        component: () => import('@/views/Project'),
        meta: {title: '项目'}
    },
    {
        name: 'demo',
        path: '/demo',
        component: () => import('@/views/Demo/index.vue'),
        meta: {title: '小案例'},
        children: [
            {
                name: 'recycleScroller',
                path: 'scroller',
                component: () => import('@/views/Demo/Tests/RecycleScroller/index.vue'),
                meta: {title: '虚拟列表'}
            },
            {
                name: 'ikunBox',
                path: 'ikunbox',
                component: () => import('@/views/Demo/Tests/IkunBox/index.vue'),
                meta: {title: '盒子'}
            }
        ]
    },
    // {
    //     name: 'fiction',
    //     path: '/fiction',
    //     component: () => import('@/views/Fiction'),
    //     meta: {title: '小说'}
    // }
]
