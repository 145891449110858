<template>
	<div class="contact-container">
		<ul>
			<li>
				<a :href="data.github" target="_blank">
					<div class="icon">
						<Icon name="icon-gitee" :size="25" color="#666" />
					</div>
					<span>{{ data.githubName }}</span>
				</a>
			</li>
			
			<li>
				<a href="https://github.com/Felikslugger?tab=repositories" target="_blank">
					<div class="icon">
						<Icon name="icon-github" :size="25" color="#666" />
					</div>
					<span>Felikslugger</span>
				</a>
			</li>
			
			<li>
				<a href="https://leetcode.cn/u/felikschen/" target="_blank">
					<div class="icon">
						<Icon name="icon-leetcode" :size="25" color="#666" />
					</div>
					<span>FeliksChen</span>
				</a>
			</li>
			
			<li>
				<a href="https://blog.csdn.net/qq_47770336?spm=1000.2115.3001.5343" target="_blank">
					<div class="icon">
						<Icon name="icon-csdn" :size="25" color="#666" />
					</div>
					<span>Feliks.</span>
				</a>
			</li>
			
			<li>
				<a href="https://juejin.cn/user/3329792962734791" target="_blank">
					<div class="icon">
						<Icon name="icon-juejin" :size="25" color="#666" />
					</div>
					<span>FeliksChen</span>
				</a>
			</li>
			
			<li>
				<a href="mailto:felikschen@163.com">
					<div class="icon">
						<Icon name="icon-email" :size="25" color="#666" />
					</div>
					<span>{{ data.mail }}</span>
				</a>
			</li>
			
			<li>
				<a
					href="tencent://message/?Menu=yes&uin=1714737510&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a595b1714f9d45">
					<div class="icon">
						<Icon name="icon-QQ-circle-fill" :size="25" color="#666" />
					</div>
					<span>{{ data.qq }}</span>
				</a>
				<div class="pop">
					<img :src="data.qqQrCode" alt="">
				</div>
			</li>
			
			<li>
				<a
					href="">
					<div class="icon">
						<Icon name="icon-weixin" :size="25" color="#666" />
					</div>
					<span>{{ data.weixin }}</span>
				</a>
				<div class="pop">
					<img :src="data.weixinQrCode" alt="">
				</div>
			</li>
			
			<li>
				<a
					href="">
					<div class="icon">
						<Icon name="icon-wangyiyun1" :size="25" color="#666" />
					</div>
					<span>Feliks9527</span>
				</a>
				<div class="pop">
					<img src="@/assets/wangyiyun.jpg" alt="">
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import Icon from "@/components/Icon/index.vue";
import {mapState} from "vuex";
export default {
	name: "myContact",
	components: {
		Icon
	},
	computed: {
		...mapState("setting", ["data"])
	}
}
</script>

<style scoped lang="scss">
@import "~@/styles/var";
.contact-container{
	$itemHeight: 30px;
	$itemWidth: 200px;
	
	list-style: none;
	padding: 20px 0;
	margin: 0;
	box-sizing: border-box;
	color: $gray;
	
	li{
		list-style: none;
		height: $itemHeight;
		line-height: $itemHeight;
		margin: 15px 0;
		position: relative;
		
		span {
			font-weight: 500;
			&:hover {
				color: $light;
			}
		}
		
		.icon-container{
			margin-right: 5px;
		}
		
		&:hover {
			.pop{
				transform: scaleX(1);
			}
		}
		
		a {
			display: flex;
			align-items: center;
		}
		
		.pop{
			position: absolute;
			left: 80px;
			bottom: $itemHeight;
			padding: 10px;
			background-color: #fff;
			box-sizing: border-box;
			border-radius: 5px;
			cursor: pointer;
			transform: scaleY(0);
			transform-origin: center bottom;
			transition: transform .3s;
			
			&::after{
				content: '';
				position: absolute;
				left: 5px;
				top: calc(100% - 20px);
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-top-color: #fff;
				transform: translateX(-50%) rotate(180deg);
			}
			
			img {
				width: 100px;
			}
		}
	}
}
</style>
